import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import ChevronLeftIcon from "../images/icons/chevron-left.inline.svg"
import ChevronRightIcon from "../images/icons/chevron-right.inline.svg"

const ResourcePagination = ({ pages, basePath, nextPage }) => {
  return (
    <div className="resources-pagination">
      <Link to={basePath} className="resources-prev">
        <ChevronLeftIcon />
      </Link>

      {pages.map(page => {
        const path = page === 1 ? basePath : `${basePath}/${page}`

        return (
          <Link to={path} key={page}>
            {page}
          </Link>
        )
      })}

      <Link
        to={`${basePath}/${nextPage}`}
        className={`resources-next ${!nextPage && "last"}`}
      >
        <ChevronRightIcon />
      </Link>
    </div>
  )
}

ResourcePagination.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.number),
  basePath: PropTypes.string.isRequired,
  nextPage: PropTypes.number,
}

ResourcePagination.defaultProps = {
  pages: [],
}

export default ResourcePagination
