import React from "react"
import Layout from "../components/layout"
import ResourceCard from "../components/ResourceCard"
import ResourcePagination from "../components/ResourcePagination"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Blog = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog | Resources" />

      <div className="header resources__header">
        <div className="container grid">
          <div className="header-content header-content--wide">
            <h1 className="resources__title">
              Industry experience to guide you
            </h1>
            <nav className="resources__breadcrumb">
              <Link to="/resources" className="link--arrow-left">
                Resources
              </Link>
              <Link to="/blog">Blog</Link>
            </nav>
          </div>
        </div>
      </div>

      <div className="resources-list container">
        {data.allMarkdownRemark.nodes.map(blog => {
          return (
            <ResourceCard
              key={blog.id}
              slug={blog.frontmatter.slug}
              thumbnail={blog.frontmatter.thumbnail}
              title={blog.frontmatter.title}
              category="blog"
              tags={blog.frontmatter.tags}
            />
          )
        })}
      </div>

      {/* <ResourcePagination basePath="/blog" pages={[1, 2, 3]} nextPage={2} /> */}
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___published] }
      filter: { frontmatter: { draft: { eq: false }, type: { eq: "blog" } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          tags
          type
          slug
          published
          thumbnail {
            childImageSharp {
              fluid(maxHeight: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
