import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"

import utils from "../../utils"

const ResourceCard = ({ title, thumbnail, img, category, tags, slug }) => {
  const resource = utils.buildResourceCategoryPath(category, slug)

  return (
    <div className="resource-card">
      <Link to={resource.href}>
        <div className="resource-card-image-wrapper">
          {thumbnail && (
            <Img fluid={thumbnail.childImageSharp.fluid} alt={title} />
          )}
        </div>

        <h4>{title}</h4>
      </Link>
      <div>
        <Link to={resource.categoryLink} className="tag tag--button">
          {resource.categoryLabel}
        </Link>
        {tags.map(tag => (
          <div className="tag" key={tag}>
            {tag}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ResourceCard
