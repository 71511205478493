exports.buildResourceCategoryPath = (category, slug) => {
  switch (category) {
    case "case-study":
      return {
        categoryLabel: "Case Study",
        categoryLink: "/case-studies",
        href: `/case-studies/${slug}`,
        root: `/case-studies`,
        rootLabel: `Case Studies`,
      }
    case "blog":
      return {
        categoryLabel: "Blog",
        categoryLink: "/blog",
        href: `/blog/${slug}`,
        root: `/blog`,
        rootLabel: `Blog`,
      }
    case "tool":
      return {
        categoryLabel: "Tool",
        categoryLink: "/tools",
        href: `/tools/${slug}`,
        root: `/tools`,
        rootLabel: `Tools`,
      }
    default:
      throw new Error(`${category} not recognized!`)
  }
}

exports.cleanResourceAssetPath = body => body.replace("../../static", "")
